<template>
  <div>
    <div id="header">
      <table style="width: 100%; table-layout: auto">
        <tr>
          <td style="text-align: left; width: 50%">
            <h4 style="font-weight: bold">
              {{
                lang === "de"
                  ? "Aufwand nach Zusammenstellung Gleitzeit- und Feriensaldo"
                  : "Expenses according to  Flexible time and Holiday"
              }}
            </h4>
            <h5 style="font-weight: bold">{{ from + " - " + to }}</h5>
          </td>
          <td style="text-align: right; width: 50%">
            <div>{{ currentCompany.name }}</div>
            <div v-if="currentSettings.master_data.address">
              {{ currentSettings.master_data.address }}
            </div>
          </td>
        </tr>
      </table>
    </div>

    <div class="first-page">
      <table style="width: 100%; justify-content: center">
        <tr style="border-top: 1px solid #333">
          <td style="width: 20%"></td>
          <td style="width: 40%; border-right: 1px solid #333; padding: 5px">
            {{ lang === "de" ? "Gleitzeit" : "Flexible Hours" }}
          </td>
          <td style="width: 40%; padding: 5px">
            {{ lang === "de" ? "Ferien" : "Holidays" }}
          </td>
        </tr>
      </table>

      <table style="width: 100%; justify-content: center;">
        <tr style="border-bottom: 1px solid #333">
          <td style="width: 20%">
            {{ lang === "de" ? "Mitarbeiter" : "Employee" }}
          </td>
          <td style="width: 10%; text-align: right;">
            Soll-<br />Stunden<br />Ifd. Jahr
          </td>
          <td style="width: 10%; text-align: right;">
            Über-<br />trag<br />Vorjahr
          </td>
          <td style="width: 10%; text-align: right;">
            Ist-<br />Stunden<br />Ifd. Jahr
          </td>
          <td
            style="width: 10%; text-align: right; border-right: 1px solid #333; padding: 5px;"
          >
            Gleitzeit-<br />saldo<br />Ifd. Jahr
          </td>
          <td style="width: 10%; text-align: right;">
            Guthaben<br />ganzes<br />Jahr
          </td>
          <td style="width: 10%; text-align: right;">
            Über-<br />trag<br />Vorjahr
          </td>
          <td style="width: 10%; text-align: right;">
            Bezug<br />Ifd.<br />Jahr
          </td>
          <td style="width: 10%; text-align: right;">
            Saldo<br />Ifd.<br />Jahr
          </td>
        </tr>
      </table>

      <table
        style="
          width: 100%;
          justify-content: center;
          border-collapse: collapse;
          page-break-inside: auto;
        "
      >
        <div
          v-for="(item, i) in currentFlexibleReport"
          :key="i"
          style="display: contents"
        >
          <tr>
            <td style="width: 20%">
              <div
                style="
                  page-break-inside: avoid;
                "
              >
                {{ item.user.first_name + " " + item.user.last_name }}
              </div>
            </td>
            <td
              style="
                width: 10%; 
                padding: 5px;
                text-align: right;"
            >
              <div
                style="
                  page-break-inside: avoid;
                "
              >
                {{ item.expected_hours }}
              </div>
            </td>
            <td
              style="
                width: 10%; 
                padding: 5px;
                text-align: right;"
            >
              <div
                style="
                  page-break-inside: avoid;
                "
              >
                {{ item.prev_hours }}
              </div>
            </td>
            <td
              style="
                width: 10%; 
                padding: 5px;
                text-align: right;"
            >
              <div
                style="
                  page-break-inside: avoid;
                "
              >
                {{ item.actual_hours }}
              </div>
            </td>
            <td
              style="
                width: 10%; 
                padding: 5px;
                text-align: right;
                border-right: 1px solid #333"
            >
              <div
                style="
                  page-break-inside: avoid;     
                "
              >
                {{ item.current_hours }}
              </div>
            </td>
            <td
              style="
                width: 10%; 
                padding: 5px;
                text-align: right;"
            >
              <div
                style="
                  page-break-inside: avoid;
                "
              >
                {{ item.holidays_credit }}
              </div>
            </td>
            <td
              style="
                width: 10%; 
                padding: 5px;
                text-align: right;"
            >
              <div
                style="
                  page-break-inside: avoid;
                "
              >
                {{ item.holidays_prev }}
              </div>
            </td>
            <td
              style="
                width: 10%; 
                padding: 5px;
                text-align: right;"
            >
              <div
                style="
                  page-break-inside: avoid;
                "
              >
                {{ item.holidays_used }}
              </div>
            </td>
            <td
              style="
                width: 10%; 
                padding: 5px;
                text-align: right;"
            >
              <div
                style="
                  page-break-inside: avoid;
                "
              >
                {{ item.holidays_available }}
              </div>
            </td>
          </tr>
        </div>
      </table>
    </div>

    <div id="footer">
      <hr style="border-top: 1px solid #333; margin-top: 0px" />
      <table style="width: 100%">
        <tr>
          <td style="text-align: right; width: 100%">
            <div>
              <span class="pageNumber"></span>/<span class="totalPages"></span>
            </div>
            <div>{{ today }}</div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { GET_COMPANY, GET_SETTINGS } from "@/core/services/store/settings";
import { GET_FLEXIBLE_REPORT } from "@/core/services/store/report";

export default {
  name: "exportListFlexibleAndHoliday",
  data() {
    return {
      today: "",
      from: "",
      to: ""
    };
  },
  computed: {
    ...mapGetters([
      "currentCompany",
      "currentSettings",
      "currentFlexibleReport"
    ]),
    lang() {
      if (this.$route.query.lang) {
        return this.$route.query.lang;
      } else {
        return "de";
      }
    }
  },
  async mounted() {
    await this.$store.dispatch(GET_COMPANY);
    await this.$store.dispatch(GET_SETTINGS);
    await this.$store.dispatch(GET_FLEXIBLE_REPORT, {
      id: this.$route.params.user_id,
      start: this.$route.query.start,
      end: this.$route.query.end
    });
    this.today = moment().format("DD.MM.YYYY");
    this.from = moment(this.$route.query.start).format("DD.MM.YYYY");
    this.to = moment(this.$route.query.end).format("DD.MM.YYYY");
    this.initFont();
  },
  methods: {
    initFont() {
      let style = document.createElement("style");
      let font = this.currentSettings.pdf_layout.pdf_font;
      if (font === "arial") {
        style.appendChild(
          document.createTextNode(
            "body { font-family: Arial,Helvetica Neue,Helvetica,sans-serif;}"
          )
        );
      } else if (font === "open sans") {
        style.appendChild(
          document.createTextNode(
            "body { font-family: 'Open Sans', sans-serif;}"
          )
        );
      } else if (font === "courier new") {
        style.appendChild(
          document.createTextNode(
            "body { font-family: Courier New,Courier,Lucida Sans Typewriter,Lucida Typewriter,monospace;}"
          )
        );
      } else if (font === "georgia") {
        style.appendChild(
          document.createTextNode(
            "body { font-family: Georgia,Times,Times New Roman,serif;}"
          )
        );
      } else if (font === "verdana") {
        style.appendChild(
          document.createTextNode(
            "body { font-family: Verdana,Geneva,sans-serif;}"
          )
        );
      } else if (font === "klavika") {
        style.appendChild(
          document.createTextNode("body {font-family: 'Klavika';}")
        );
        style.appendChild(
          document.createTextNode(
            "@font-face {font-family: 'Klavika';src: url('/fonts/klavika-rg/Klavika-Regular.otf') format('truetype');font-weight: normal;font-style: normal;}"
          )
        );
        style.appendChild(
          document.createTextNode(
            "@font-face {font-family: 'Klavika';src: url('/fonts/klavika-rg/Klavika-Bold.otf') format('truetype');font-weight: bold;font-style: normal;}"
          )
        );
      }
      document.head.appendChild(style);
    },
    formatDate(val) {
      if (val) {
        return moment(val).format("DD.MM.YYYY");
      }
    }
  }
};
</script>

<style scoped>
.first-page {
  margin-left: 7%;
  margin-right: 7%;
}

.first-page #content {
  width: 100%;
}

.first-page #content #title {
  margin-bottom: 100px;
}

.first-page #content #info {
  text-align: left;
  margin-bottom: 100px;
}

.first-page #footer {
  margin-top: 400px;
}

.first-page #footer #footer-content {
  justify-content: center;
  width: 100%;
}

h1 {
  font-size: 45px;
  margin-bottom: 0px;
}

h3 {
  font-size: 23px;
  margin-top: 0px;
}

#header {
  margin-left: 7%;
  margin-right: 7%;
}

#footer {
  margin-left: 7%;
  margin-right: 7%;
}
</style>

<style>
body {
  display: block;
  color: black;
  line-height: 1.1;
  background-color: #fff;
  font-size: 11px !important;
}

p {
  margin-bottom: 0;
}

i {
  color: #000 !important;
}

hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

table {
  table-layout: fixed;
}
</style>
